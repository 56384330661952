import React from "react";
import loader from "../../assets/images/loader/loader.gif";
import loaderWebP from "../../assets/images/loader/loader.webp";
const MainLoader = () => (
  <div className="main-loader">
    <picture>
    <source srcset={loaderWebP} type="image/webp"/>
    <img alt="loader" src={loader} loading="lazy"/>
    </picture>
  </div>
);
export default MainLoader;
