import * as types from "../Actions/actionTypes";
const initialState = {
  firstName: "",
  lastName: "",
  email:""
};
const ebook = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_EBOOK_DETAIL:
      return {
        ...state,
        firstName: action.data.firstName,
        lastName: action.data.lastName,
        email: action.data.email,
      };
    default:
      return state;
  }
};

export default ebook;