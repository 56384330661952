import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();
let gtagId = process.env.REACT_APP_GOOGLE_ANALYTIC_ID;
const analytics = () => {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l !== "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", gtagId);
};
const script = document.createElement("script");
script.async = true;
script.src = "https://www.googletagmanager.com/ns.html?id=" + gtagId;

document.head.appendChild(script);
let enableAnalytics = process.env.REACT_APP_ENABLE_ANALYTIC;
if (enableAnalytics && enableAnalytics === "true") {
  analytics();
}
history.listen(() => {
  // check if google analytic is enable, load the analytic scripts on every route change
  if (enableAnalytics && enableAnalytics === "true") {
    analytics();
  }
});
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
