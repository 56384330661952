import * as types from "../Actions/actionTypes";
const initialState = {
  company: {},
  error: "",
};
const company = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_COMPANY_DETAIL:
      return {
        ...state,
        company: { ...action.data },
      };
    default:
      return state;
  }
};

export default company;
