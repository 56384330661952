import * as types from "../Actions/actionTypes";
const initialState = {
  userInfo: {},
  error: "",
};
const user = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_INFO:
      return {
        ...state,
        userInfo: { ...action.data },
      };
    case types.CLEAR_USER_INFO:
      return {
        ...state,
        userInfo: initialState.userInfo,
      };
    default:
      return state;
  }
};

export default user;
