import * as types from "../Actions/actionTypes";
const initialState = {
  plan: {},
  error: "",
};
const plan = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PLAN_DETAIL:
      return {
        ...state,
        plan: { ...action.data },
      };
    default:
      return state;
  }
};

export default plan;
