import React, { useEffect } from "react";
import { Suspense, lazy } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./Services/Redux";
import WOW from "wowjs";
import "./assets/css/custom.css";
import "./assets/css/custom.dev.css";
import "./assets/css/admin.css";
import "flatpickr/dist/themes/material_orange.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/inrelay-style.css";
import MainLoader from "./Components/UI/MainLoader";
import PrivteRoutes from "./PrivteRoutes";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
const Main = lazy(() => import("./Pages/Main"));
const Registration = lazy(() => import("./Pages/Registration"));
const Login = lazy(() => import("./Pages/Login"));
const EmailConfirmation = lazy(() => import("./Pages/EmailConfirmation"));
const ChooseAccountType = lazy(() => import("./Pages/ChooseAccountType"));
const Settings = lazy(() => import("./Pages/Settings"));
const AdminInviteEmployee = lazy(() => import("./Pages/AdminInviteEmployee"));
const WelCome = lazy(() => import("./Pages/WelCome"));
const ShareStoties = lazy(() => import("./Pages/ShareStories"));
const AdminDashboard = lazy(() => import("./Pages/AdminDashboard"));
const AdminSettingProfile = lazy(() => import("./Pages/AdminSettingProfile"));
const AdminQueue = lazy(() => import("./Pages/AdminQueue"));
const AdminReport = lazy(() => import("./Pages/AdminReport"));
const Blog = lazy(() => import("./Pages/Blog"));
const Automotive = lazy(() => import("./Pages/Automotive"));
const About = lazy(() => import("./Pages/About"));
const ContactUs = lazy(() => import("./Pages/ContactUs"));
const PrivacyPolicy = lazy(() => import("./Pages/PrivacyPolicy"));
const TermsOfServices = lazy(() => import("./Pages/TermsOfServices"));
const Pricing = lazy(() => import("./Pages/Pricing"));
const ChooseAdminAnotherPlan = lazy(() =>
  import("./Pages/ChooseAdminAnotherPlan")
);
const Forgotpassword = lazy(() => import("./Pages/Forgotpassword"));
const Resetpassword = lazy(() => import("./Pages/ResetPassword"));
const serviceUnavilable = lazy(() => import("./Pages/SeviceUnavilable"));
const EngagementUserDetails = lazy(() =>
  import("./Pages/EngagementUserDetails")
);
const shortUrlPage = lazy(() => import("./Pages/ShortUrlPage"));
const PageNotFound = lazy(() => import("./Pages/PageNotFound"));
const ChooseCompanyAccount = lazy(() => import("./Pages/ChooseCompanyAccount"));
const ThankYou = lazy(() => import("./Pages/ThankYou"));
const FreeDemo = lazy(() => import("./Pages/FreeDemo"));
const ComingSoonRedirection = lazy(() => import("./Pages/ComingSoonRedirection"));

const App = () => {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  return (
    <Provider store={store}>
      <Router>
          <Suspense fallback={<MainLoader />}>
          <Switch>
            <Route exact path="/" component={Main} />
            <Route exact path="/registration" component={Registration} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgot-password" component={Forgotpassword} />
            <Route exact path="/verify/:uid/:token" component={Resetpassword} />
            <Route
              exact
              path="/email-confirmation"
              component={EmailConfirmation}
            />
            <Route
              exact
              path="/choose-account-new"
              component={ChooseCompanyAccount}
            />
            <Route exact path="/choose-account" component={ChooseAccountType} />
            <Route exact path="/welcome/:uuid" component={WelCome} />
            <Route exact path="/share-stories" component={ShareStoties} />
            <Route exact path="/blog" component={Blog} />
            <Route exact path="/about" component={About} />
            <Route exact path="/contact_us" component={ContactUs} />
            <Route exact path="/automotive-industry" component={Automotive} />
            <Route exact path="/privacy-Policy" component={PrivacyPolicy} />
            <Route
              exact
              path="/terms-of-services"
              component={TermsOfServices}
            />
            <Route exact path="/pricing" component={Pricing} />
            <Route exact path="/linkedin" component={LinkedInPopUp} />
            <Route exact path="/thank-you" component={ThankYou} />
            <Route exact path="/free-demo" component={FreeDemo} />

            <PrivteRoutes exact path="/dashboard" component={AdminDashboard} />
            <PrivteRoutes
              exact
              path="/profile"
              component={AdminSettingProfile}
            />
            <PrivteRoutes exact path="/queue" component={AdminQueue} />
            <PrivteRoutes exact path="/report" component={AdminReport} />
            <PrivteRoutes exact path="/setting/:slag" component={Settings} />
            <PrivteRoutes
              exact
              path="/manage-user"
              component={AdminInviteEmployee}
            />
            <PrivteRoutes
              exact
              path="/choose-another-plan"
              component={ChooseAdminAnotherPlan}
            />
            <PrivteRoutes
              exact
              path="/service-unavilable"
              component={serviceUnavilable}
            />
            <PrivteRoutes
              exact
              path="/engagement-user-details/:id/:filter"
              component={EngagementUserDetails}
            />
            <Route exact path="/shorturl/:unique_id" component={shortUrlPage} />
            <Route exact path="/redirector/:uuid" component={ComingSoonRedirection} />
            
            <Route path="*" component={PageNotFound} />
            </Switch>
          </Suspense>
      </Router>
    </Provider>
  );
};

export default App;
